
/* eslint-disable max-params */
import { C2bAppointmentService, SellerVariantService, SellerSlotsService } from "../../../service";
import { generateConfirmAppointmentPayload, generateSellOnlineAppointmentPayload } from "../../../utils/appointment-flow";
import Types from "./types";
import { resetReducerKeys } from "../../../constants/app-constants";
import { formatDateTimeSlotsForHomeInspection } from "./format-date-time-helper";
import { SLOT_PANNER_TOKEN } from "../../../constants/ae/sell-car-online";
import { INSPECTION_TYPE } from "../../../constants/seller-app-constants";

const toggleOtpModal = (flag) => (dispatch) => dispatch({
    type: Types.TOGGLE_OTP_MODAL,
    flag
});

const toggleAppointmentLocationPicker = (flag) => ({
    type: Types.TOGGLE_APPOINMENT_LOCATION_PICKER,
    flag
});

const setIsFetchInspectionSlots = (flag) => ({
    type: Types.SET_IS_FETCH_INSPECTION_SLOTS,
    flag
});

const setIsSellerPreferredDateSelection = (flag) => ({
    type: Types.SET_IS_SELLER_PREFERRED_DATE_SELECTION,
    flag
});

const setLeadBrand = ({ data }) => ({
    type: Types.SET_EVALUATION_BRAND,
    data
});

const setLeadModel = ({ data }) => ({
    type: Types.SET_EVALUATION_MODEL,
    data
});

const setLeadYear = ({ data }) => ({
    type: Types.SET_EVALUATION_YEAR,
    year: data
});

const setLeadCustomerData = ({ data }) => ({ // set data via key:value pair
    type: Types.SET_EVALUATION_CUSTOMER,
    customer: data
});

const setLeadDataNode = ({ data }) => ({ // set data via key:value pair
    type: Types.SET_EVALUATION_DATA,
    ...data
});

const setLeadDataVariants = (data) => ({
    type: Types.SET_EVALUATION_DATA_BY_VARIANTS,
    data
});

const setLeadBookingDetails = (data) => ({
    type: Types.SET_BOOKING_DETAILS,
    data
});

const fetchLeadAppointmentInfoRequest = () => ({
    type: Types.FETCH_LEAD_APPOINTMENT_INFO_REQUEST
});

export const fetchLeadAppointmentInfoSuccess = (data) => ({
    type: Types.FETCH_LEAD_APPOINTMENT_INFO_SUCCESS,
    data
});

const fetchLeadAppointmentInfoFailure = (error) => ({
    type: Types.FETCH_LEAD_APPOINTMENT_INFO_FAILURE,
    error
});

const fetchLeadAppointmentInfo = (appointmentId, payload) => (dispatch, getState) => {
    dispatch(fetchLeadAppointmentInfoRequest());
    const { carEvaluation:
        { inspectionType: prevInspectionType, appointmentId: appId, bookingDetail: { address: prevAddress } },
        user: { secureToken } = {}
    } = getState();

    return new Promise((resolve, reject) => {
        C2bAppointmentService.fetchLeadAppointmentInfo(appointmentId, payload, secureToken)
            .then(response => {
                const { inspectionType, bookingDetail } = response?.data || {};
                const isRefreshed = appId === appointmentId && prevInspectionType;
                dispatch(fetchLeadAppointmentInfoSuccess({
                    ...response.data,
                    inspectionType: (isRefreshed || !inspectionType) ? prevInspectionType : inspectionType,
                    bookingDetail: {
                        ...bookingDetail,
                        address: (isRefreshed && prevInspectionType !== INSPECTION_TYPE.ONLINE) ? prevAddress : bookingDetail?.address,
                        walkInAppointment: isRefreshed && prevInspectionType !== INSPECTION_TYPE.WALK_IN ? false : bookingDetail?.walkInAppointment
                    }
                }));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchLeadAppointmentInfoFailure(error));
                reject(error);
            });
    });
};

const createLeadAppointmentRequest = () => ({
    type: Types.CREATE_LEAD_APPOINTMENT_REQUEST
});

const createLeadAppointmentSuccess = (data) => ({
    type: Types.CREATE_LEAD_APPOINTMENT_SUCCESS,
    data
});

const resetLeadCreation = () => ({
    type: resetReducerKeys.RESET_CAR_EVALUATION
});

const createLeadAppointmentFailure = (error) => ({
    type: Types.CREATE_LEAD_APPOINTMENT_FAILURE,
    error
});

const createLeadAppointment = () => (dispatch, getState) => {
    dispatch(createLeadAppointmentRequest());
    const { carEvaluation, cities: { selectedCity }, user } = getState();
    const payload = generateSellOnlineAppointmentPayload(carEvaluation, selectedCity, user);
    return new Promise((resolve, reject) => {
        C2bAppointmentService.createLeadAppointment(payload, user?.secureToken)
            .then(response => {
                dispatch(createLeadAppointmentSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(createLeadAppointmentFailure(error));
                reject(error);
            });
    });
};

const updateLeadAppointmentRequest = () => ({
    type: Types.UPDATE_LEAD_APPOINTMENT_REQUEST
});

const updateLeadAppointmentSuccess = (data) => ({
    type: Types.UPDATE_LEAD_APPOINTMENT_SUCCESS,
    data
});

const updateLeadAppointmentFailure = (error) => ({
    type: Types.UPDATE_LEAD_APPOINTMENT_FAILURE,
    error
});

const updateLeadAppointment = () => (dispatch, getState) => {
    dispatch(updateLeadAppointmentRequest());
    const { carEvaluation: { appointmentId }, carEvaluation, cities: { selectedCity }, user } = getState();
    const payload = generateSellOnlineAppointmentPayload(carEvaluation, selectedCity, user);
    return new Promise((resolve, reject) => {
        C2bAppointmentService.updateLeadAppointment(appointmentId, payload, user?.secureToken)
            .then(response => {
                dispatch(updateLeadAppointmentSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(updateLeadAppointmentFailure(error));
                reject(error);
            });
    });
};

const fetchDsOfferedPriceRequest = () => ({
    type: Types.FETCH_DS_OFFERED_PRICE_REQUEST
});

const fetchDsOfferedPriceSuccess = (data) => ({
    type: Types.FETCH_DS_OFFERED_PRICE_SUCCESS,
    data
});

const fetchDsOfferedPriceFailure = (error) => ({
    type: Types.FETCH_DS_OFFERED_PRICE_FAILURE,
    error
});

const fetchDsOfferedPrice = (appointmentId) => (dispatch, getState) => {
    dispatch(fetchDsOfferedPriceRequest());
    const { user: { secureToken } = {} } = getState();
    return new Promise((resolve, reject) => {
        C2bAppointmentService.fetchDsOfferedPrice(appointmentId, secureToken)
            .then(response => {
                dispatch(fetchDsOfferedPriceSuccess({ ...response.data }));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchDsOfferedPriceFailure());
                reject(error);
            });
    });
};

const fetchAppointmentSlotsRequest = () => ({
    type: Types.FETCH_APPOINTMENT_SLOT_REQUEST
});

const fetchAppointmentSlotsSuccess = (data) => ({
    type: Types.FETCH_APPOINTMENT_SLOT_SUCCESS,
    data
});

const fetchAppointmentSlotsFailure = (error) => ({
    type: Types.FETCH_APPOINTMENT_SLOT_FAILURE,
    error
});

const fetchAppointmentSlots = (appointmentId, payload, inspectionType) => (dispatch) => {
    dispatch(fetchAppointmentSlotsRequest());
    return new Promise((resolve, reject) => {
        SellerSlotsService.getAvailableInspectionSlots(appointmentId, payload, SLOT_PANNER_TOKEN)
            .then(response => {
                const { availableSlots = [] } = response?.data || {};
                const formattedSlots = formatDateTimeSlotsForHomeInspection(availableSlots, inspectionType);
                dispatch(fetchAppointmentSlotsSuccess({ ...response.data, dateTimeSlot: { ...formattedSlots } }));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchAppointmentSlotsFailure(error));
                reject(error);
            });
    });
};

const fetchVideoAppointmentSlots = (appointmentId, payload) => (dispatch) => {
    dispatch(fetchAppointmentSlotsRequest());
    return new Promise((resolve, reject) => {
        SellerSlotsService.getAvailableVideoInspectionSlots(appointmentId, payload, SLOT_PANNER_TOKEN)
            .then(response => {
                const { availableSlots = [] } = response?.data || {};
                const formattedSlots = formatDateTimeSlotsForHomeInspection(availableSlots);
                dispatch(fetchAppointmentSlotsSuccess({ ...response.data, dateTimeSlot: { ...formattedSlots } }));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchAppointmentSlotsFailure(error));
                reject(error);
            });
    });
};

const bookInspection = (appointmentId, inspectionType) => (_, getState) => {
    const { carEvaluation, user: { secureToken } } = getState();
    const payload = generateConfirmAppointmentPayload(carEvaluation, inspectionType);
    return new Promise((resolve, reject) => {
        C2bAppointmentService.bookInspection(appointmentId, inspectionType, payload, secureToken)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const updatePriceAcceptedStatus = (appointmentId) => (_, getState) => {
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        C2bAppointmentService.updatePriceStatus(appointmentId, secureToken)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const carSelfInspectionRequest = () => ({
    type: Types.CAR_SELF_INSPECTION_REQUEST
});

const carSelfInspectionSuccess = (data) => ({
    type: Types.CAR_SELF_INSPECTION_SUCCESS,
    data
});

const carSelfInspectionFailure = (error) => ({
    type: Types.CAR_SELF_INSPECTION_FAILURE,
    error
});

const fetchCarSelfInspection = (payload) => (dispatch) => {
    dispatch(carSelfInspectionRequest());
    return new Promise((resolve, reject) => {
        SellerVariantService.fetchCarSelfInspectionQuestions(payload)
            .then(response => {
                dispatch(carSelfInspectionSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(carSelfInspectionFailure(error));
                reject(error);
            });
    });
};

const cancelInspection = (appId, payload) => (dispatch, getState) => {
    dispatch(updateLeadAppointmentRequest());
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        C2bAppointmentService.cancelSellerInspection(appId, payload, secureToken)
            .then(response => {
                dispatch(updateLeadAppointmentSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(updateLeadAppointmentFailure(error));
                reject(error);
            });
    });
};

const setSellerCarDetails = (payload) => (dispatch) => {
    dispatch({ type: Types.SET_SELLER_CAR_DETAILS, data: payload });
};

const setAppointmentSlots = (payload) => (dispatch) => {
    dispatch({ type: Types.SET_APPOINTMENT_SLOTS, data: payload });
};

const setSellerRa = (payload) => (dispatch) => {
    dispatch({ type: Types.SET_SELLER_RA, data: payload });
};

const confirmCRMAppointment = (appointmentId) => (dispatch) => {
    dispatch(fetchLeadAppointmentInfoRequest());
    return new Promise((resolve, reject) => {
        C2bAppointmentService.provideCRMLeadConfirmation(appointmentId)
            .then(response => {
                dispatch(fetchLeadAppointmentInfoSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchLeadAppointmentInfoFailure(error));
                reject(error);
            });
    });
};

const setCarConditionStepIndex = (index) => ({
    type: Types.SET_CAR_CONDITION_STEP_INDEX,
    index
});

const setInspectionType = (inspectionType) => ({
    type: Types.SET_INSPECTION_TYPE,
    inspectionType
});

export const fetchPaymentStatus = (appId, bookingOrderId, orderId, token) => (_, getState) => {
    const {user: {secureToken}} = getState();
    return new Promise((resolve, reject) => {
        C2bAppointmentService.getPaymentStatus(appId, bookingOrderId, orderId, secureToken || token)
            .then(response => {
                resolve(response?.data);
            }).catch(error => {
                reject(error);
            });
    });
};

export {
    toggleOtpModal,
    toggleAppointmentLocationPicker,
    setLeadBrand,
    setLeadModel,
    setLeadYear,
    setLeadDataNode,
    setLeadBookingDetails,
    createLeadAppointment,
    updateLeadAppointment,
    setLeadCustomerData,
    fetchDsOfferedPrice,
    fetchLeadAppointmentInfo,
    resetLeadCreation,
    fetchAppointmentSlots,
    setLeadDataVariants,
    updatePriceAcceptedStatus,
    fetchCarSelfInspection,
    carSelfInspectionSuccess,
    setSellerCarDetails,
    setAppointmentSlots,
    cancelInspection,
    setIsFetchInspectionSlots,
    setSellerRa,
    setIsSellerPreferredDateSelection,
    fetchVideoAppointmentSlots,
    confirmCRMAppointment,
    bookInspection,
    setCarConditionStepIndex,
    setInspectionType
};
